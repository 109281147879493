import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "react-loader";

import * as oidcApi from "api/oidcApi";
import { generateStateAndNonce } from "store/actions/tokenActions";

function LoginPage({ state, nonce, generateParams }) {
  if (state === null || nonce === null) {
    generateParams();
  } else {
    oidcApi.beginAuth({ state, nonce });  
  }

  return <Loader />; 
}

const mapStateToProps = (state) => ({
  state: state.token.state,
  nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
  generateParams: () => dispatch(generateStateAndNonce()),
});

const propTypes = {
  state: PropTypes.any,
  nonce: PropTypes.any,
  generateParams: PropTypes.any
}

LoginPage.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
