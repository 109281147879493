import {
    FETCHING,
    SUCCESS_FETCH,
    FAIL_FETCH,
    CLEAR_ENTRIES
} from '../actions/actionTypes';
import { resetEntires } from '../../utils';

export const REQUEST_KEY_DEFAULT = 'default';

export const initialState = {
    [REQUEST_KEY_DEFAULT]: {
        status: null,
        response: null
    }
};

const reducer = (
    state = initialState,
    { type, key, response, clearKeys } = {}
) => {
    switch (type) {
        case FETCHING:
            return { ...state, [key]: { status: FETCHING } };
        case SUCCESS_FETCH:
            return { ...state, [key]: { status: SUCCESS_FETCH, response } };
        case FAIL_FETCH:
            return { ...state, [key]: { status: FAIL_FETCH, response } };
        case CLEAR_ENTRIES:
            return resetEntires(state, clearKeys);
        default:
            return state;
    }
};

export default reducer;
