function authHeader() {
    const user = JSON.parse(sessionStorage.getItem('wb-lite-user'));

    if (user && user.token) {
        return { 'Authorization': `Bearer ${user.token}` };
    }
    return {};

}

export default authHeader