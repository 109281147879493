import { userActionTypes } from '../actions/actionTypes';

const initialState = { loggedIn: false, user: null }

function authentication(state = initialState, action) {
    switch (action.type) {
        case userActionTypes.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userActionTypes.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userActionTypes.LOGIN_FAILURE:
            return {};
        case userActionTypes.LOGOUT:
            return initialState;
        default:
            return state
    }
}

export default authentication;