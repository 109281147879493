
import React, { useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";


// pages


import Dashboard from 'pages/Dashboard';



import AuthPage from "containers/OneLoginAuth/AuthPage";
import OneLoginLoginPage from "containers/OneLoginAuth/LoginPage";

const isLoggedIn = () => sessionStorage.getItem("wb-lite-user");


export default function Router() {
  
   


  return useRoutes([
    {
      path: "/",
      element: isLoggedIn() ? <Dashboard /> : <OneLoginLoginPage />,
      children: [
        { path: "/", element: <Dashboard /> },
  
      ],
    },
    { path: "/login", element: <OneLoginLoginPage /> },
    { path: "/auth", element: <AuthPage /> }
  ]);
}
