export const FETCHING = 'FETCHING';
export const SUCCESS_FETCH = 'SUCCESS_FETCH';
export const FAIL_FETCH = 'FAIL_FETCH';
export const CLEAR_ENTRIES = 'CLEAR_ENTRIES';

// One Login Action Types
export const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
export const RECEIVE_ID_TOKEN = "RECEIVE_ID_TOKEN";
export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA";
export const GENERATE_STATE_AND_NONCE = "GENERATE_STATE_AND_NONCE";
export const CLEAR_ALL = "CLEAR_ALL";

export * from './userActionTypes';


