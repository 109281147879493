import { combineReducers } from 'redux';
import apiReducer from './apiReducer';
import authentication from './authReducer';
import apiResponseReducer from './apiResponseReducer';
import tokenReducer from './tokenReducer';
import profileReducer from './profileReducer';

const staticReducers = {
    api: (state = {}) => state,
    apiReducer,
    authentication,
    token: tokenReducer,
    profile: profileReducer,
    apiData: apiResponseReducer
};

const createReducer = asyncReducers =>
    combineReducers({
        ...staticReducers,
        ...asyncReducers
    });

export default createReducer;