import React, { useEffect, useState } from "react";
import axios from 'axios'; 

const EdgeAuth = require('akamai-edgeauth')
const http = require('http') // Module for the test

const propTypes = {};

function Dashboard() {  
    const EA_HOSTNAME = 'edgeauth.akamaized.net'  
    const EA_ENCRYPTION_KEY = 'ea7ea42726047a6a8c32ef0649e1e7210f77ffebd5e10fdd666bba206d78b758'   
    const DURATION = 14400 // seconds  
    
    const load = async () => {    
      const res = await axios.get('https://api.ipify.org?format=json')   
      const ip = res?.data?.ip ?? ""    
      console.log('IP************* ', ip)    
      
      const ea = new EdgeAuth({      
        key: EA_ENCRYPTION_KEY,      
        windowSeconds: DURATION,      
        escapeEarly: true,      
        tokenName: 'hdnea',      
        ip    
      })    
      
      const acl = ["*"]    
      const token = ea.generateACLToken(acl)    
      console.log('TOKEN ************ ', token, ea.options)    
      
      window.location.href = `https://ffuat.iwanttfc.com?hdnea=${token}`  
    
    }  
    
    React.useEffect(() => {        
      load();      
    })  
    
    return (   
       <div>      
        {/* <iframe src="https://ffuat.iwanttfc.com" style={{height: '100%', width: '100%'}} title="iwantTFC" /> */}          
        </div>  
        );
        
    }
    
    Dashboard.propTypes = propTypes;
    
    export default Dashboard;