import history from 'utils/history';

import {
    userActionTypes
} from './userActionTypes';



export const login = (user) => {
    sessionStorage.setItem('wb-lite-user', JSON.stringify(user));

    return {
        type: userActionTypes.LOGIN_SUCCESS,
        user
    }

};


export const logout = () => {
    sessionStorage.removeItem('wb-lite-user');
    history.push('/login');
    return { type: userActionTypes.LOGOUT };
}