import {
    FETCHING,
    SUCCESS_FETCH,
    FAIL_FETCH,
    CLEAR_ENTRIES
} from '../actions/actionTypes';

export const INITIAL_STATE = {
    data: []
};

const responseReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case SUCCESS_FETCH:
            return { ...state, data: action.payload };


        default:
            return state;
    }

}

export default responseReducer;