import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";
import isEmpty from "lodash/isEmpty";
import Loader from "react-loader";


import { login } from "store/actions/authActions";

import {
  receiveAccessToken,
  receiveIdToken,
} from "store/actions/tokenActions";
import { receiveProfileData } from "store/actions/profileActions";


// const redirect = () => {
//   const encoded = Base64.encode(username + ':' + password);
//   $.ajax({
//       url: "/app/test",
//       type: "GET",
//       beforeSend: function(xhr) {
//           xhr.setRequestHeader('Authorization', 'Basic ' + encoded);
//       },
//       success: function() {
//           setTimeout(function() {
//               window.location.href = '/app/test.html';
//           }, 333);
//       }
//   });
// }
function AuthPage({ profile, receiveProfile, receiveTokens }) {
  if (isEmpty(profile)) {
    const response = parse(window.location.hash);
    if (response.error) {
      alert(response.error_description);
    }
    // console.log('response   ', response)
    sessionStorage.setItem('wb-lite-token', JSON.stringify({
      id_token_hint: response.access_token
    }));
    receiveTokens(response.access_token, response.idToken);
    receiveProfile(jwtDecode(response.id_token));
    console.log('AAAAAAAAAAAA')
    // Render loader
    
    return <Loader />;
  }

  login(profile)
  console.log('BBBBBBBBBBBBB')
  return <Navigate to="/" />;
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  state: state.token.state,
  nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
  receiveProfile: (data) => dispatch(receiveProfileData(data)),
  receiveTokens: (accessToken, idToken) => {
    dispatch(receiveAccessToken(accessToken));
    dispatch(receiveIdToken(idToken));
  },
  login
});

const propTypes = {
  profile: PropTypes.any,
  receiveProfile: PropTypes.any,
  receiveTokens: PropTypes.any
}
AuthPage.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
